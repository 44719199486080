<template>
  <q-page>
    <div class="layout-padding">
      <div class="vehicle">
        <q-card class="bg-white">
          <q-card-section class="vehicle-card-title">
            <span class="vehicle-detail" v-html="$t('vehicle_from_company', { vehicle: selectedVehicle.vehicle.type, company: selectedVehicle.taxi_company })" />
            <span> {{ selectedVehicle.price.text }} </span>
          </q-card-section>
          <q-card-section class="row">
            <div class="col-xs-4">
              <m-image :src="selectedVehicle.vehicle.images[0]" :fallback="fallback" class="img" />
            </div>
            <div class="vehicle-info col-xs-4">
              <q-icon class="icon" name="people" />
              <p v-if="selectedVehicle.vehicle.passenger_capacity">
                {{ $t('num.seats', { num: selectedVehicle.vehicle.passenger_capacity }) }}
              </p>
              <p v-else>
                {{ $t('abbreviation.not_applicable') }}
              </p>
            </div>
            <div class="vehicle-info col-xs-4">
              <q-icon class="icon" name="card_travel" />
              <p v-if="selectedVehicle.vehicle.luggage_capacity">
                {{ $t('num.bags', { num: selectedVehicle.vehicle.luggage_capacity}) }}
              </p>
              <p v-else>
                {{ $t('abbreviation.not_applicable') }}
              </p>
            </div>
            <div class="info">
              <q-input
                v-model="info.phone_number"
                :error="$v.info.phone_number.$invalid"
                :label="$t('your_phone_number')"
                type="tel"
              />
              <q-input
                v-model="info.flight_number"
                :label="$t('flight.number')"
              />
              <q-input
                v-model="info.instructions"
                type="textarea"
                :label="$t('taxi.message_to_driver')"
              />
            </div>
            <q-btn color="primary" size="lg" class="btn-search" :label="$t('confirm.information')" @click="saveInfo" />
          </q-card-section>
        </q-card>
      </div>
    </div>
  </q-page>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { get as getUser } from 'api/users'
import { required } from 'vuelidate/lib/validators'
import { handleErrors } from 'utils/utils'

export default {
  filters: {
    round (val) {
      return Math.round(val * 10) / 10
    }
  },
  data () {
    return {
      info: {
        instructions: '',
        phone_number: '',
        flight_number: ''
      },
      loading: false,
      fallback: require('assets/blank-vehicle-hire.png')
    }
  },
  computed: {
    ...mapGetters({
      origin: 'rideHailing/origin',
      destination: 'rideHailing/destination',
      routeInfo: 'rideHailing/routeInfo',
      time: 'rideHailing/time',
      selectedVehicle: 'rideHailing/selectedVehicle',
      traveller: 'rideHailing/traveller'
    })
  },
  methods: {
    saveInfo () {
      this.$v.$touch()
      if (this.$v.$error) {
        this.$q.notify(this.$t('phone_number_required'))
        return
      }
      this.loading = true
      this.$store.dispatch('rideHailing/saveAdditionalInfo', _.pickBy(this.info))
        .then(() => {
          this.loading = false
          this.$router.push({ name: 'ondemand-office-ride-hailing-summary' })
        })
        .catch(err => {
          this.loading = false
          handleErrors(err)
        })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      getUser(vm.traveller.value)
        .then((res) => {
          vm.info.phone_number = res.data.meta.phone_number
        })
    })
  },
  validations: {
    info: {
      phone_number: { required }
    }
  }
}
</script>

<style lang="stylus" scoped>
.layout-padding
  max-width 768px

.info
  width 100%

.vehicle-detail
  line-height 1.1

.vehicle-info
  display flex
  justify-content center
  align-items center
  flex-direction column

.icon
  font-size 26px
.line
  height calc(100% - 5px)
  width 2px
  border dashed 1px black
  margin-left 10px

.route-text
  margin-left 20px

.route-point
  position relative
  min-height 40px

.route-point:first-child span
  margin-bottom 10px

.route-point:last-child
  min-height auto

.dot
  width 15px
  height 15px
  border-radius 50%
  background black
  position absolute
  top 0px
  left -28px

.action
  padding 5px;
  position absolute;
  bottom 0
  width 100%
  left 0

  @media (min-width 768px) {
    position relative
  }

.btn-search
  margin-top 20px
  width 100%
  transition width .3s

.btn-time
  width: 100%;

</style>

<style lang="stylus">
@keyframes fadeIn {
  0%   { opacity: 0 }
  100% { opacity: 1 }
}

.vehicle-card-title
  display flex
  justify-content space-between

.vehicle > *
  animation: fadeIn .3s forwards
</style>
