<template>
  <q-page>
    <div class="layout-padding">
      <div v-if="!vehicleGroups" class="loading">
        <q-spinner-arrow v-if="partner.spinnerType === 'arrow'" :color="partner.hex" size="64" class="loader" />
        <q-spinner-geo v-else size="64" class="loader" />
        <p>{{ $t('loading.searching.taxi') }}</p>
      </div>
      <div v-else class="results">
        <div v-if="!vehicleGroups.length" style="display: grid; width: 100%;">
          <h5 class="empty">
            {{ $t('no_taxis_found') }}
          </h5>
        </div>
        <div v-for="p in vehicleGroups" :key="p.name">
          <h4>{{ p.category_label }}</h4>
          <h6>{{ p.description }}</h6>
          <vehicle-card v-for="v in p.options" :key="v.token" :vehicle="v" />
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
import { mapGetters } from 'vuex'
import vehicleCard from './vehicle-card'
import QSpinnerGeo from 'components/MSpinner/Geo'
import QSpinnerArrow from 'components/MSpinner/Arrow'
export default {
  components: { vehicleCard, QSpinnerGeo, QSpinnerArrow },
  data () {
    return {
      slide: null
    }
  },
  computed: {
    ...mapGetters({
      vehicleGroups: 'rideHailing/vehicleGroups',
      partner: 'partner'
    })
  },
  methods: {
    selectVehicle (vehicle) {
      this.$store.dispatch('rideHailing/setValue', { prop: 'selectedVehicle', payload: vehicle })
    },
    confirm () {
      this.$store.dispatch('rideHailing/confirmDetails')
        .then(() => {
          this.$router.push({ name: 'ondemand-ride-hailing-summary' })
        })
    },
    getResults () {
      this.$store.dispatch('rideHailing/search')
    }
  }
}
</script>

<style lang="stylus" scoped>
.layout-padding
  max-width 768px

.loading
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

.loading h5
  text-align center
  text-transform uppercase

h4, h6
  text-align center

h4
  margin-top 20px
h6
  margin-bottom 20px

.loading
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

.loader
  width: 100px;
  height: 100px;
  margin: auto;
  transform: translate(140%);
</style>
