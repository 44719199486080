<template>
  <q-card>
    <q-card-section>
      <q-item multiline class="vehicle" @click.native="goToDetail">
        <q-item-section side class="img-container">
          <m-image
            :src="vehicle.vehicle.images[0]"
            :fallback="fallback"
            :style="'max-width: 64px; max-height: 64px'"
          />
        </q-item-section>
        <q-item-section>
          <q-item-label label>
            {{ vehicle.title }}
          </q-item-label>
          <q-item-label caption>
            {{ vehicle.description }}
          </q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-btn :label="priceText" outline class="btn-price" />
        </q-item-section>
      </q-item>
    </q-card-section>
  </q-card>
</template>

<script>
import { priceText as priceTextUtil } from 'utils/onwardTravel'

export default {
  props: ['vehicle'],
  data () {
    return {
      fallback: require('assets/blank-vehicle-hire.png')
    }
  },
  computed: {
    priceText () {
      return priceTextUtil(this.vehicle)
    }
  },
  methods: {
    goToDetail () {
      this.$store.dispatch('rideHailing/setValue', { prop: 'selectedVehicle', payload: this.vehicle })
      this.$router.push({ name: 'ondemand-office-ride-hailing-vehicle' })
    }
  }
}
</script>

<style lang="stylus" scoped>
.detail
  font-size 14px

.vehicle
  cursor pointer

.btn-price
  color #1e88e5!important
  border-color #1e88e5!important

.vehicle:hover .btn-price
  background #1e88e5!important
  border-color #1e88e5!important
  color white!important
</style>
