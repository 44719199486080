<template>
  <q-page
    class="layout-padding"
  >
    <div class="row q-col-gutter-xs">
      <div class="col-xs-12">
        <q-card class="bg-white">
          <q-card-section :class="`bg-primary text-white`">
            {{ $t('booking.summary') }}
          </q-card-section>
          <q-card-section>
            <div
              class="row"
              style="margin-top: 16px"
            >
              <div class="col-4">
                <sub>{{ $t('vehicle_type') }}</sub>
                <h6 class="text-grey-9">
                  {{ summary.ride.vehicle.type }}
                  <br>
                  {{ summary.ride.title }}
                </h6>
              </div>
              <div class="col-4">
                <sub>{{ $t('description') }}</sub>
                <h6 class="text-grey-9">
                  {{ summary.ride.description }}
                </h6>
              </div>
              <div class="col-3">
                <m-image :src="summary.ride.vehicle.images[0]" :fallback="fallback" width="142" height="88" />
              </div>
            </div>

            <div
              class="row q-col-gutter-sm"
              style="margin-top: 16px"
            >
              <div class="col-6">
                <sub>{{ $t('pick_up') }}</sub>
                <h6 class="text-grey-9">
                  {{ summary.pickup_time.date | date }}
                </h6>
                {{ summary.origin }}
              </div>
              <div class="col-6">
                <sub>{{ $t('drop_off') }}</sub>
                <h6 class="text-grey-9" />
                {{ summary.destination }}
              </div>
            </div>
            <q-separator class="hr" />
            <h6>{{ $t('other_travel_information') }}</h6>
            <div class="grid">
              <q-item v-if="summary.phone_number">
                <q-item-section>
                  <q-item-label label>
                    {{ $t('phone_number') }}
                  </q-item-label>
                  <q-item-label caption>
                    {{ summary.phone_number }}
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item v-if="summary.flight_number">
                <q-item-section>
                  <q-item-label label>
                    {{ $t('flight.number') }}
                  </q-item-label>
                  <q-item-label caption>
                    {{ summary.flight_number }}
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item v-if="summary.instructions">
                <q-item-section>
                  <q-item-label label>
                    {{ $t('instructions') }}
                  </q-item-label>
                  <q-item-label caption>
                    {{ summary.instructions }}
                  </q-item-label>
                </q-item-section>
              </q-item>
            </div>
            <q-separator class="hr" />
            <div v-for="(item, i) in summary.order_items" :key="i" class="order-item">
              <div class="description">
                {{ item.description }}
              </div>
              <div class="grid">
                <q-item>
                  <q-item-section>
                    <q-item-label label>
                      {{ $t('net_amount') }}
                    </q-item-label>
                    <q-item-label caption>
                      {{ item.net_amount.text }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <q-item-label label>
                      {{ $t('tax.amount') }}
                    </q-item-label>
                    <q-item-label caption>
                      {{ item.tax_amount.text }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <q-item-label label>
                      {{ $t('total_cost') }}
                    </q-item-label>
                    <q-item-label caption>
                      {{ item.total.text }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </div>
            </div>

            <q-separator class="hr" />
            <h6 class="row text-bold">
              <span class="offset-5 col-4 text-grey-9 text-right">{{ $t('total_cost') }}</span>
              <span class="col-3 text-grey-9 text-right">{{ summary.total_amount.text }}</span>
            </h6>
          </q-card-section>
        </q-card>
      </div>
      <div class="col-sm-12">
        <q-card class="bg-primary text-white">
          <q-card-section class="text-h6">
            {{ $t('information') }}
            <div class="float-right">
              <q-icon name="info" color="white" />
            </div>
          </q-card-section>
          <q-card-section>{{ $t('taxi.final_fare_calculation') }}</q-card-section>
        </q-card>
      </div>
      <div class="col-xs-12">
        <m-attach-to-booking-request
          :suggested="summary.suggested_name"
          :username="traveller.value"
          :journey="journey"
          @change="setJourney"
          @reasonChange="(val) => reason_for = val"
          @labelChange="(val) => labels = val"
        />
      </div>
      <payment-buttons
        :total-amount="summary.total_amount.amount"
        :requester="requester"
        :journey="journey"
        :reason-for="reason_for"
        :labels="labels"
        :booking-token="summary.booking_token"
        content-type="ondemand-office-ride-hailing"
      />
      <sub
        class="col-12 text-center text-faded"
        style="margin-top: 8px"
        v-html="$t('mobilleo_privacy_and_terms', { privacyURL: partner.privacyURL, termsURL: partner.termsURL })"
      />
    </div>
  </q-page>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import authentication from 'mixins/authentication'
import date from 'utils/date-time'
import paymentButtons from 'pages/payments/payment-method-buttons'
export default {
  name: 'OndemandRideHailingSummary',
  components: {
    paymentButtons
  },
  filters: {
    date: value => date.toMediumDateTime(value)
  },
  mixins: [authentication],
  data () {
    return {
      reason_for: null,
      labels: null,
      fallback: require('assets/blank-vehicle-hire.png')
    }
  },
  computed: {
    ...mapGetters({
      summary: 'rideHailing/summary',
      traveller: 'rideHailing/traveller',
      requester: 'rideHailing/requester',
      journey: 'rideHailing/journey',
      partner: 'partner'
    })
  },
  created () {
    this.$store.dispatch('rideHailing/setJourney', { name: this.summary.suggested_name, reference: null })
  },
  methods: {
    setJourney (val) {
      this.$store.dispatch('rideHailing/setJourney', val)
    }
  }
}
</script>

<style lang="stylus" scoped>
.layout-padding
  max-width 768px!important
  margin auto
span, .hr
  margin-top 16px
button
  width 100%
.summary-main
  padding 20px

.grid
  display grid
  grid-template-columns repeat(auto-fill, 33%);
  font-weight 400
  align-items baseline

.order-item
  padding 10px 0

.grid > div
  padding 10px 0

.grid > div:nth-child(2)
  text-align center

.grid > div:nth-child(3)
  text-align right

.description
  font-weight 700

h6.text-bold
  padding 0 10px
</style>
